import React from "react";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";

import { useTranslation } from "../../../lang";

const defaultImage = "https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg";

const Variations = styled.div`
	padding: 10px 0;
`;

const Set = styled.div`
	& + & {
		margin-top: 15px;
	}
	> label {
		margin: 0;
		padding: 0;
		font-size: 14px;
		color: #4d4d4d;
		display: block;
		margin-bottom: 5px;
		text-transform: capitalize;

		span {
			margin: 0;
			padding: 0;
			font-weight: bold;
			margin-right: 5px;
		}
	}
	ul {
		margin-left: -2.5px;
		display: flex;
		flex-wrap: wrap;
	}
`;

const activeMixin = css`
	font-weight: bold;
	border-color: #112c55 !important;
	color: #112c55;
	&:before {
		background-color: rgba(17, 44, 85, 0.08) !important;
	}
`;

const Item = styled.li`
	background-color: #fff;
	margin: 0;
	padding: 0;
	line-height: initial;
	display: inline-block;
	vertical-align: middle;
	margin: 2.5px;
	text-decoration: none;
	color: #4d4d4d;
	font-size: 14px;
	min-width: 100px;
	height: 100px;
	line-height: 34px;
	border: solid 1px #e3e3e3;
	border-radius: 2px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		padding: 0px 10px;
		text-transform: capitalize;
		display: none;
		&:not(:only-child):last-child {
			padding-left: 5px;
		}
		&:not(:only-child):first-child {
			padding-right: 5px;
		}
	}
	img {
		width: 98px;
		height: 98px;
		object-fit: contain;
	}
	label {
		font-weight: bold;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background-color: transparent;
	}

	${(props) => props.active && activeMixin};

	&:hover {
		border-color: #b3b6b8;
		&:before {
			background-color: rgba(0, 0, 0, 0.01);
		}
	}
`;

const Color = styled.div`
	background-color: ${(props) => (props.color ? props.color : "transparent")};
	width: 20px;
	height: 20px;
	border-radius: 10px;

	&:not(:only-child):first-child {
		margin-left: 10px;
	}
	&:not(:only-child):last-child {
		margin-right: 10px;
	}
`;

const getAssociatedName = (name, product, lang) => {
	if (typeof product[name.toLowerCase()] === "string")
		return product[name.toLowerCase()];
	if (
		lang &&
		product[name.toLowerCase()] &&
		typeof product[name.toLowerCase()] === "object" &&
		product[name.toLowerCase()][lang]
	)
		return product[name.toLowerCase()][lang];

	return product.title || product.title_eng || product.title_pt;
};

const getAllImages = (asociation) => {
	if (asociation == null || asociation.products == null) return [];

	return asociation.products.map((index) => {
		if (
			index.imagesUrl == null ||
			(index.imagesUrl && index.imagesUrl.length === 0) ||
			index.imagesUrl[0] == null
		)
			return null;
		return (
			<link
				rel="prefetch"
				key={index.imagesUrl[0].url}
				href={index.imagesUrl[0].url}
				as="image"
			/>
		);
	});
};

function AsociatedProducts({
	asociatedProducts,
	viewItem,
	onMouseEnter,
	onMouseLeave,
}) {
	const [_t, lang] = useTranslation();
	const timer = React.useRef();

	const handleMouseLeave = () => {
		if (timer.current) clearTimeout(timer.current);
		timer.current = setTimeout(() => {
			if (onMouseLeave) onMouseLeave();
		}, 100);
	};
	const handleMouseEnter = (url) => {
		if (timer.current) clearTimeout(timer.current);
		if (onMouseEnter) onMouseEnter(url);
	};

	const handleItemView = (prod) => {
		viewItem(prod.slug, prod);
		if (typeof window !== "undefined" && analytics)
			analytics.track("Clicked Associated Product");
	};

	if (asociatedProducts == null) return null;
	return (
		<>
			<Variations>
				{asociatedProducts.map((index, i) => (
					<Set key={`associated-product-set-${i}`}>
						<label>
							<span>{index.title}:</span>
						</label>
						<ul>
							{index.products.map((prod) => {
								if (prod == null) return null;
								return (
									<Item
										key={`associated-product-${prod._id}${index.title}`}
										onClick={() => handleItemView(prod)}
										onMouseEnter={() =>
											handleMouseEnter(
												prod.imagesUrl &&
													prod.imagesUrl.length > 0
													? prod.imagesUrl[0].url
													: defaultImage,
											)
										}
										onMouseLeave={handleMouseLeave}>
										<img
											src={
												prod.imagesUrl &&
												prod.imagesUrl.length > 0
													? prod.imagesUrl[0].thumb
													: defaultImage
											}
										/>
										<span>
											{getAssociatedName(
												index.title,
												prod,
												lang,
											)}
										</span>
									</Item>
								);
							})}
						</ul>
					</Set>
				))}
			</Variations>
		</>
	);
}

export default React.memo(AsociatedProducts);
